* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  transition: 1s;
}
#secPrivacy .navbar .navbar-brand img {
  height: 50px;
  object-fit: cover;
}

#secPrivacy .navbar ul li a i {
  font-size: 20px;
}

#secPrivacy span {
  font-weight: bold;
  border-bottom: 2px solid #000;
}
#secPrivacy h2
{
    font-weight: bold;
    margin-top: 20px;
}
#secPrivacy p
{
    line-height:27px;
    font-weight:bold;
    margin-top: 20px;

    

}
#secPrivacy ul li
{
    font-weight: bolder;
    line-height: 35px;


}