* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}
html {
  scroll-behavior: smooth;
  transition:1s;
}
.action
{
  position:fixed;
  bottom:20px;
  left:60px;
  width: 80px;
  height:60px;
  background:url('../Img/linkedin-1.png');
  background-size: cover;
  border-radius:2%;
  cursor: pointer;
  z-index: 1000;
  background-position: center;
  animation: MoveUpDown 2s linear infinite;
  border:1px solid #eeeeee;
}
@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
}

.dropdown-item
{
  font-size:0.82rem !important;
}
#sidemenu {
  position: fixed;
  top: 0;
  right: 0;
  background: url("../Img/adi-goldstein-fARVSWznseM-unsplash.jpg");
  background-size: cover;
  width: 30%;
  height: 100vh;
  padding: 4rem;
  color: #fff;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.5s ease-in-out;
  overflow: hidden;
  z-index: 1000;
}
#footerSec i {
  font-size: 1.6rem;
  cursor: pointer;
}
#footerSec h1 {
  font-size: 2.2rem;
}
#sidemenu.active {
  transform: translate3d(0, 0, 0);
}
#sidemenu ul li {
  list-style-type: none;
  margin: 20px;
  font-size: 1.5rem;
  cursor: pointer;
}
#section1 .navbar .navbar-brand img {
  height: 50px;
  object-fit: cover;
}
.navbar ul li a {
  font-size: 1rem;
  color: #000 !important;
  font-weight: bold;
  margin: 0px 5px;
}
.navbar ul li a i {
  font-size: 20px;
}
.navbar #services {
  position: relative;
}
.navbar .services ul {
  border: 0px solid #000;
  position: absolute;
  width: 300px;
}

#services ul li {
  color: #000;
  font-weight: bold;
  list-style-type: none;
  font-size: 0.89rem;
  margin: 5px auto;
  display: none;
  transition: 0.56s;
  border: 1px solid #000;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  text-decoration: none;
}
#services:hover li {
  display: block;
  z-index: 1000;
  cursor: pointer;
  text-decoration: none;
}

#section1 {
  height: 100vh;
  background-image: url("../Img/landing.png");
  background-size: cover;
  background-attachment: fixed;
  text-align: center;
}
#section1 img {
  height: 180px;
}
#section1 h1,
h3 {
  margin-left: 20px;
}
#section1 .btn {
  border: none;
  box-shadow: none;
  outline: none;
  border: 1px solid #424242;
  border-radius: 0px;
  padding: 10px 20px;
  color: #000;
  font-size: 1.2rem;
  position: relative;
  overflow: hidden;
  transition: 0.78s;
  z-index: 0;
  font-weight: bold;
}
#section1 .btn::after {
  content: "";
  height: 100%;
  width: 0%;
  background: #424242;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.78s;
  overflow: hidden;
  z-index: -1;
}
#section1 .btn:hover::after {
  width: 100%;
}
#section1 .btn:hover {
  color: #fff;
}
#section2 h1 {
  font-size: 3rem;
  color: #fff;
}
#section2 h1 span {
  font-weight: bold;
  border-bottom: 2px solid #fff;
}
#section2 p {
  color: #000;
  color: #fff;
}
#section2 ul li {
  font-size: 0.8rem;
  color: #000;
  list-style-type: none;
  margin: 10px auto;
  color: #fff;
  font-weight: bold;
}
#section2 {
  background-image: url("../Img/linke.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  height: 100vh;
}

#section3 ul li {
  list-style-type: none;
  margin: 5px auto;
}

#section3 img {
  height: 300px;
}

#section4 span {
  font-weight: bold;
  border-bottom: 1px solid #000;
}
#section5 {
  background: #0d47a1;
}

#section5 img {
  height: 200px;
}

#section5 h2 {
  color: #fff;
}

#section5 .btn {
  box-shadow: none;
  margin-top: 10px;
  border: none;
  border-radius: 0;
  background: #fff;
  padding: 10px 20px;
}
#section6 {
  background: #fff;
  background-size: cover;
  background-size: cover;
}
#section6 .number p {
  border: 2px solid #1a237e;
  border-radius: 50%;
  width: 30px;
  font-weight: bold;
  height: 30px;
  padding: 10px 10px auto;
}

#section6 h1 {
  text-align: center;
  color: #000;
}

#section6 p {
  text-align: center;
  margin-top: 20px;
  color: #000;
  font-size: 0.9rem;
}

#section6 span {
  font-weight: bold;
  border-bottom: 1px solid#000;
}

#section6 .row .col-lg-3 {
  transition: 0.23s;
}
#section6 .row .col-lg-3 a {
  color: #1a237e;
  text-decoration: none;
  border: 1px solid #1a237e;
  padding: 5px 10px;
  transition: 0.28s;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

#section6 .row a::after {
  content: "";
  height: 100%;
  width: 5%;
  background: #1a237e;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.78s;
  overflow: hidden;
  z-index: -1;
}

#section6 .row .col-lg-3:hover a {
  color: #fff;
}
#section6 .row :hover a::after {
  width: 100%;
}
#section6 .row .col-lg-3:hover {
  box-shadow: 0px 2px 5px 0px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid #000;
  border-style: dashed;
}

#section6 i {
  color: #1a237e;
  font-size: 50px;
}
#section6 .row {
  text-align: center;
}
#section6 h2 {
  color: #1a237e;
  font-size: 1.3rem;
  margin-top: 20px;
  font-weight: bold;
}

#section7 h1 {
  color: #1a237e;
  text-align: left;
}
#section7 {
  border-left: 20px solid #0d47a1;
  transition: 1s;
}
#section7:hover {
  border-left: 30px solid #0d47a1;
}
#section7 span {
  font-weight: bold;
  border-bottom: 1px solid #000;
}

#section7 ul {
  border: 0px solid #000;
}
#section7 ul li {
  list-style-type: none;
  text-align: left;
  margin: 20px auto;
  display: flex;
  font-size: 1.2rem;
  font-weight: bold;
}

#section7 ul li:hover i {
  color: #0d47a1;
}
#section7 .btn {
  color: #1a237e;
  text-decoration: none;
  border: 1px solid #1a237e;
  padding: 5px 10px;
  transition: 0.28s;
  position: relative;
  z-index: 0;
  overflow: hidden;
  border-radius: 0;
}

#section7 .btn::after {
  content: "";
  height: 100%;
  width: 5%;
  background: #1a237e;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.78s;
  overflow: hidden;
  z-index: -1;
}

#section7 .btn:hover::after {
  width: 100%;
}
#section7 .btn:hover {
  color: #fff;
}

#section7 ul li i {
  font-size: 30px;
  transition: 0.2s;
}
#section8 h1 {
  text-align: center;
}

#section8 span {
  color: #1a237e;
  font-weight: bold;
  border-bottom: 2px solid #1a237e;
}
#section8 i {
  font-size: 50px;
}
#section8 p {
  text-align: center;
}

#section8 .mainIcon {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 10px;
}
#section8 h4 {
  margin-top: 20px;
  font-weight: bold;
  text-align: left;
}
#section8 h2 {
  font-weight: bold;
  text-align: center;
}
#section8 ul {
  margin-top: 20px;
}
#section8 ul li {
  font-size: 0.89rem;
  text-align: left;
  list-style-type: none;
}
#section8 ul li i {
  font-size: 18px;
}
#section9 {
  background: url("../Img/newCover.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

#section9 h1 {
  text-align: center;
  color: #fff;
}
#section9 .row .panel h3 {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: left;
}
#section9 span {
  color: #fff;
  font-weight: bold;
  border-bottom: 2px solid #fff;
}
#section9 .row .panel {
  border: 1px solid #e3f2fd;
  border-radius: 10px;
  padding: 15px;
}

#section9 .row .panel h3 {
  text-align: left;
  color: #fff;
  font-size: 1.2rem;
}
#section9 .row .panel p {
  color: #fff;
  font-size: 0.9rem;
}

#section10 {
  background: url("../Img/footer.png");
  background-size: cover;
}

#section10 h2 {
  text-align: left;
  color: #fff;
}

#section10 ul {
  text-align: left;
  margin-top: 20px;
}

#section10 ul li {
  color: #fff;
  list-style-type: none;
  margin: 5px auto;
  font-weight: bold;
  font-size: 0.78rem;
}


#section10 .col-lg-3 {
  margin-top: 20px;
}
#section10 p {
  color: #fff;
  font-weight: bold;
  font-size: 0.78rem;
}
#section11 {
  text-align: center;
  background-color: #000;
}

#section11 h3 {
  color: #fff;
  font-size: 0.78rem;
}

@media only screen and (max-width: 768px) {
  #section1 {
    background-position-x: 20%;
  }
  #section1 img {
    border: 0px solid #000;
    object-fit: cover;
    height: 140px;
  }
  #section2 {
    background: transparent;
  }
  #section2 h1 {
    color: #000;
  }
  #section2 h1 span {
    font-weight: bold;
    border-bottom: 2px solid #000;
    color: #000;
  }
  #section2 p {
    color: #000;
  }
  #section2 ul li {
    font-size: 0.8rem;
    color: #000;
    list-style-type: none;
    margin: 10px auto;
    color: #000;
  }

  #navbarNav ul li {
    border: 0px solid #000;
    text-align: center;
  }
  #navbarNav ul li a {
    border: 0px solid #000;
    text-align: center;
    font-size: 1.1rem;
  }
  #navbarNav i {
    border: 1px solid #000;
    display: none;
  }
  #section3 h1 {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }
  #section4 h1 {
    font-size: 1.5rem;
  }
  #section5 {
    padding: 20px;
  }
  #section5 .btn {
    width: 100%;
  }
  #section5 img {
    display: none;
  }
  #section9 {
    padding: 20px;
  }
  #section9 .col-lg-4 {
    margin: 5px auto;
  }
  #section4 {
    padding: 20px;
    text-align: center;
  }
  #section10 {
    background-color: #000;
  }
  #section11 h3 {
    color: #fff;
    font-size: 0.78rem;
  }
  #services ul 
  {
    position:absolute;
    left: 20%;
  }
  #services ul  li
  {
    border: 1px solid #000;
  }
}
