* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  transition: 1s;
}
#termsec1 .navbar .navbar-brand img {
  height: 50px;
  object-fit: cover;
}

#termsec1 .navbar ul li a i {
  font-size: 20px;
}

#termsec1 h1
{
    font-weight: bold;
}
#termsec1  span
{
    border-bottom: 2px solid #000;
}
#termsec1 h2
{
    margin-top:20px;
    font-weight: bold;
}
#termsec1 p
{
    line-height:27px;
    font-weight:bold;
    margin-top: 20px;

}
#termsec1 ul li
{
    font-weight: bolder;
    line-height: 35px;
}
