* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
    transition:1s;
  }
#CustomerSection1 .navbar .navbar-brand img {
  height: 50px;
  object-fit: cover;
}

#CustomerSection1.navbar ul li a i {
  font-size: 20px;
}

#CustomerSection1 {
  height: 100vh;
  text-align: center;
  
}
#CustomerSection1 h1
{
    text-align: center;
}
#CustomerSection1 span
{
    color: #F9A826;
    font-weight:bold;
    border-bottom: 2px solid #F9A826;
}
#CustomerSection1 p
{
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
}

#CustomerSection1 .row p 
{
    text-align: left;
}
#CustomerSection2 ul li a
{
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
}
#CustomerSection2
{
    padding-top: 70px;
}
#myTabContent p
{
    color: #000;
    font-weight: bold;
}
#CustomerSection3
{
    background: #eeeeee;
    text-align: center;
}
#CustomerSection3 i
{
    color: #F9A826;
    font-size:80px;
}
#CustomerSection3 h2
{
    font-size:1.5rem;
    font-weight: bold;
}
#CustomerSection3  p
{
    margin-top:20px;
    font-weight: bold;
}
#CustomerSection4 
{
    background-image: url('../Assets/Img/girl.png');
    background-size: cover;
    height:500px;
}
#CustomerSection4 img
{
    height:100px;
}
#CustomerSection4 .btn
{
    border: 1px solid #000;
    border-radius: 2px;
    background:#F9A826;
    font-weight: bold;
    box-shadow: none;
}
@media only screen and (max-width: 768px) {
    #CustomerSection1 img
    {
        display: none;
    }
    #CustomerSection3
    {
        padding:20px;
    }
    #CustomerSection4
    {
        padding:40px;
    }
}