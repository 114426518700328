* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  transition:1s;
}
#FeaturedSection1 .navbar .navbar-brand img {
  height: 50px;
  object-fit: cover;
}

#FeaturedSection1 .navbar ul li a i {
  font-size: 20px;
}

#FeaturedSection1 {
  height: 100vh;
  background-size: cover;
  transition: all 1s;
  overflow: hidden;
  position: relative;
  background-attachment: fixed;
  text-align: center;
}

#FeaturedSection1 h1
{
    text-align: center;
    font-weight: bold;
}
#FeaturedSection1 span
{
    color: #F50057;
    font-weight: bold;
    border-bottom:2px solid #F50057;
}
#FeaturedSection1 p
{
    text-align: center;
    font-weight: bold;
    margin-top: 30px;
}

#FeaturedSection1 ul li
{
    color: #000;
    font-weight: bold;
    text-align: left;
}
#FeaturedSection1 .row p
{
    text-align: left;
}
#FeaturedSection2 h1
{
    font-size:1.4rem;

}
#FeaturedSection2 span
{
    color: #F50057;
    font-weight: bold;
    border-bottom: 2px solid #F50057;
}
#FeaturedSection2 ul 
{
    margin-top:20px;
}