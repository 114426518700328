* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  -webkit-scroll-behavior: smooth;
}
#section1Technology .navbar .navbar-brand img {
  height: 50px;
  object-fit: cover;
}
#section1Technology .navbar ul li a {
  font-size: 1rem;
  color: #000 !important;
  font-weight: bold;
  margin: 0px 5px;
}
#section1Technology .navbar ul li a i {
  font-size: 20px;
}
#section1Technology {
  height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-image: url("../Assets/Img/freddie-marriage-vSchPA-YA_A-unsplash.jpg");
  text-align: center;
}
#section1Technology h1 {
  color: #000 !important;
  font-size: 4rem;
  margin-top: 100px;
}
@media only screen and (max-width: 768px){
  #section1Technology h1 {
    font-size: 3rem;
  }
}
#section1Technology p {
  color: #000 !important;
  font-weight: bold;
}
#section1Technology .btn {
  color: #000 !important;
  border: 1px solid #000;
  box-shadow: none;
  position: absolute;
  top: 75%;
}
#section2Technology h1
{
  font-size:2rem;
}

#section1Technology .btn i {
  font-size: 1.5rem;
  animation: MoveUpDown 1s linear infinite;
  justify-content: center;
  align-items: center;
  padding: 0;
}

#zendesk{
  margin-bottom: 2rem;
  align-items: center;
  text-align: center;
  padding: 1rem;
}
#zendesk h2{
  color: #444;
  font-weight: bold;
}
#zendesk .hand-shake-icon{
  font-size: 5rem;
  margin-top: 5rem;
  color: #333;
  filter: blur(0.5px);
}
@media only screen and (max-width: 768px){
  
}

#mongo img
{ 
    height:100px;
}
/* #mongo .btn
{
  margin-left: 40px;
} */
#mongo h1
{
  font-size:2rem;
}

#mongo
{
  text-align: center;
}
@keyframes MoveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

#section3Technology h5 {
  color: #000;
  font-weight: bold;
  margin-top: 10px;
}
#section3Technology .btn {
  border: 0px solid #000;
  border-radius: 0;
  background: #1565c0;
  color: #fff;
  box-shadow: none;
}

#section3TechnologyPart
{
  background-image: url('../Assets/Img/nreTech.png');
  background-size: cover;
  background-position: center;
}