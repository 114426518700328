* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  transition:1s;
}
#businessSection1 .navbar .navbar-brand img {
  height: 50px;
  object-fit: cover;
}
#businessSection1 .navbar ul li a {
  font-size: 1rem;
  color: #000 !important;
  font-weight: bold;
  margin: 5px auto;
}

#businessSection1 {
  height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  text-align: center;
}
#businessSection1 span
{
    color: #F9A826;
    font-weight: bold;
    border-bottom: 2px solid #F9A826;
}
#businessSection1 h1
{
    text-align: center;
    font-weight: bold;
}

#businessSection1 p
{
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
}
#businessSection1 .row p
{
    color:#000;
    font-weight: bold;
    text-align: left;
    margin-top: 20px;
}
#businessSection2
{
  padding-top:50px
}
#businessSection2 h3
{
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}
#businessSection2 p
{
    font-weight: bold;
}
#businessSection3 .row i
{
    border: 0px solid #000;
    font-size:50px;
    color: #F9A826;
}
#businessSection3 p
{
    font-weight: bold;
}
#businessSection4
{
    background-color: #F9A826;
}
#businessSection4 .btn
{
    border: 2px solid #000;
    font-weight: bold;
    border-radius:0;

}
#businessSection4 h3
{
    font-size:1.3rem;
    margin-top:40px;
}

@media only screen and (max-width: 768px) {
  #businessSection2
  {
    border: 0px solid #000;
    padding-top: 500px;
  }
  #businessSection4
  {
    padding: 50px;
  }
  #businessSection3
  {
    border: 0px solid #000;
    padding: 20px;
  }
}