* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}
html {
  scroll-behavior: smooth;
  transition: 1s;
}
#aboutSection1 .navbar .navbar-brand img {
  height: 50px;
  object-fit: cover;
}
#aboutSection1 .navbar ul li a i {
  font-size: 20px;
}
#aboutSection1 {
  height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-image: url("../Assets/Img/lucas-franco-aRTjFXs6HNc-unsplash.jpg");
  text-align: center;
}
#aboutSection1 h1 {
  color: #fff;
  text-align: center;
  font-size: 4rem;
  margin-top: 180px;
}
#aboutSection1 span {
  font-weight: bold;
  border-bottom: 2px solid #fff;
}
#aboutSection1 p {
  color: #fff;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 35px;
}
#aboutSection1 .btn {
  background: #fff;
  box-shadow: none;
  border-radius: 0px;
  font-size: 1.2rem;
  font-weight: bold;
}
#aboutSection2 .col-lg-6 {
  height: 500px;
}
#aboutSection2 h1 {
  margin-top: 100px;
}
#aboutSection2 span {
  font-weight: bold;
  border-bottom: 1px solid #000;
  color: #0d47a1;
}
#aboutSection2part1 {
  background: url("../Assets/Img/you-x-ventures-vbxyFxlgpjM-unsplash.jpg");
  background-position: center;
  background-size: cover;
}
#aboutSection3 .col-lg-6 {
  height: 500px;
}
#aboutSection3 h1 {
  margin-top: 50px;
}
#aboutSection3 span {
  font-weight: bold;
  border-bottom: 1px solid #000;
  color: #0d47a1;
}
#aboutSection3part1 {
  background: url("../Assets/Img/20722875.jpg");
  background-position: center;
  background-size: cover;
}
#aboutSection4 i {
  font-size: 100px;
  color: #0d47a1;
}
#aboutSection4 span {
  border-bottom: 1px solid #000;
  font-weight: bold;
  color: #0d47a1;
}
#aboutSection5 h1 {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 10px;
  color: #0d47a1;
}
#aboutSection5 p {
  text-align: center;
  font-size: 0.89rem;
  margin-top: 20px;
  font-weight: bold;
}
#aboutSection5 i {
  font-size: 50px;
  color: #0d47a1;
}
@media only screen and (max-width: 768px) {
  #aboutSection2part1 {
    display: none;
  }
  #aboutSection3part1 {
    display: none;
  }
  #aboutSection4 {
    border: 0px solid #000;
    padding-top: 60px;
  }
}
