* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
    transition:1s;
  }

#digitalSection1 .navbar .navbar-brand img {
  height: 50px;
  object-fit: cover;
}

#digitalSection1.navbar ul li a i {
  font-size: 20px;
}
#digitalSection1
{
    text-align: center;
}


#digitalSection1 h1
{
    text-align: center
}
#digitalSection1 span
{
    color: #F9A826;
    font-weight:bold;
    border-bottom: 2px solid #F9A826;
}
#digitalSection1 p
{
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
}
#digitalSection1 .row p
{
    text-align: left;
    margin-top: 20px;
}
#digitalSection1 h5
{
    font-weight: bold;
}
#digitalSection1 .card
{
    height:500px;
}
#digitalSection1 .card img
{
    height:200px;
    object-fit: cover;
}
#secondCard .card
{
    height:600px;
}