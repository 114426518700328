* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#appSection1 .navbar .navbar-brand img {
  height: 50px;
  object-fit: cover;
}

#appSection1.navbar ul li a i {
  font-size: 20px;
}

#appSection1 {
  height: 100vh;
  background-image: url('../Assets/Img/bussinessPIC.png');
  background-size: cover;
  transition: all 1s;
  overflow: hidden;
  position: relative;
  background-attachment: fixed;
  text-align:center;
}

#appSection1 span
{
    color: #F50057;
    font-weight: bold;
    border-bottom:2px solid #F50057;
}
#appSection1 h1
{
    color: #000;
    font-weight: bold;
    text-align: center;
}
#appSection1 p
{
    text-align: center;
    margin-top:20px;
    color: #000;
    font-weight: bold;
}
#appSection1 .row p
{
    text-align: right;
}
#appSection1 .row h5
{
    text-align: right;
    font-weight: bold;
}
#appSection2 img
{
    height:60px;
}
#appSection2 .row h1
{
    font-size:1.9rem;
}
#appSection2 span
{
    color: #F50057;
    font-weight:bold;
    border-bottom:2px solid #F50057;
}