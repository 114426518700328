* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  transition:1s;
}
#Contactsection1 .navbar .navbar-brand img {
  height: 50px;
  object-fit: cover;
}
#Contactsection1 .navbar ul li a {
  font-size: 1rem;
  color: #000 !important;
  font-weight: bold;
  margin: 0px 5px;
}
#Contactsection1 .navbar ul li a i {
  font-size: 20px;
}

#Contactsection1 {
  height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-image: url("../Assets/Img/avel-chuklanov-9OWS4lT5iqQ-unsplash.jpg");
  text-align: center;
}
#Contactsection1 h1 {
  text-align: center;
  color: #000;
  font-size: 5rem;
  margin-top: 120px;
}
#Contactsection1 span {
  font-weight: bold;
  border-bottom: 3px solid #000;
}
#Contactsection1 p {
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}
#Contactsection1 .btn {
  border: 5px solid #000;
  height: 50px;
  border-radius: 0;
  border-radius: 50%;
  width: 50px;
  animation: MoveUpDown 1s linear infinite;
  margin-top: 200px;
}

#Contactsection1 .btn i {
  color: #000;
  font-size: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes MoveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
#Contactsection2 img {
  height: 80px;
  margin-top: 70px;
}

#backContact {
  background-image: url("../Assets/Img/hunters-race-MYbhN8KaaEc-unsplash.jpg");
  background-size: cover;
  background-position: center;
  height: 600px;
}
#Contactsection3 {
  text-align: center;
  background-image: url("../Assets/Img/neonbrand-mgYAR7BzBk4-unsplash.jpg");

  background-size: cover;
}
#Contactsection3 h1 {
  color: #fff;
  font-weight: bold;
}
#Contactsection3 p {
  color: #fff;
}
#Contactsection3 img {
  height: 40px;
  margin-top: 40px;
  cursor: pointer;
}
#Contactsection4 i {
  font-size: 2rem;
}
#Contactsection5 img {
  height: 70px;
}
#Contactsection5 h6 {
  font-weight: bold;
}
#Contactsection5 ul {
  text-align: left;
}
#Contactsection5 .form-control {
  box-shadow: none;
  border: none;
  border: 1.5px solid #000;
}
#Contactsection5 .btn {
  background: #0d47a1;
  color: #fff;
  font-weight: bold;
  box-shadow: none;
  border: none;
}

@media only screen and (max-width: 768px) {
  #Contactsection1 h1
  {
    font-size:3rem;
  } 
  #Contactsection3
  {
    padding:50px;
  }

}